import React, { useContext, useEffect, useState } from "react";
import "./Orders.css";
import Order from "../Order/Order";
import {
  ItemDetailsData,
  OrderedItem,
  pastOrderItems,
} from "../../../../Utils/DefinitionsInterfaces";
import { getPastOrdersAPI } from "../../../../Utils/CheckoutAPIs/CheckoutAPIs";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
// import { logEvent } from "firebase/analytics";
import {
  fAnalytics,
  logFirebaseEvent,
} from "../../../../Utils/firebase/firebaseConfig";
import { LogEventBody, logEvent } from "../../../../Utils/api/auth";
import { AuthContext } from "../../../../App";

const Orders = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const itemIds = urlParams.get("item_ids");
  const checkoutItemIds = itemIds?.split('-').map(Number) || []

  const [pastOrders, setPastOrders] = useState<OrderedItem[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    auth: {
      student_username,
      name,
      phone,
      email,
      grade,
      report_generated,
      school,
      examDate,
      showMockOlympiad,
      examLink,
    },
  } = useContext(AuthContext);

  const location = useLocation();
  const itemId = location.state.itemId.split("-")[0];
  const getPastOrders = () => {
    getPastOrdersAPI()
      .then((pastOrders) => {
        setLoading(true);

        if (pastOrders.data.status === "Success") {
          console.log(pastOrders);
          setLoading(false);
          const filteredItems = pastOrders.data.data.orderedItems.filter((e) =>
            e.itemDetails.some((f) => f.id === +itemId)
          );
          console.log({ filteredItems, itemId });
          setPastOrders(filteredItems);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    logFirebaseEvent("user_completed_payment_successully", {
      user_id_custom: student_username,
      grade: grade,
      school: school,
      itemName: pastOrders[0]?.itemDetails[0]?.name,
    }).then(() => {
      const logBody: LogEventBody = {
        studentUserID: student_username,
        eventName: 'payment_success',
        productIDs: checkoutItemIds,
      }
      logEvent(logBody).then(() => getPastOrders())
    });
  }, []);

  return (
    <div className="orders">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 100,
            background: "rgba(0,0,0,0.7)",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="orders-title">
            <div className="text-wrapper-2">Product description</div>
          </div>
          {pastOrders.map((e) => {
            console.log("invoiceNO", e.invoiceNo);
            return (
              <Order
                invoiceNo={e.invoiceNo}
                stateCheckoutItem={e.itemDetails}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Orders;
