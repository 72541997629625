import React from "react";
import "./CheckoutFooter.css";

const CheckoutFooter = () => {
  return (
    <footer className="footer">
      <div className="illus-container">
        <p className="text-wrapper-12">Streak Tech | All rights reserved</p>
      </div>
    </footer>
  );
};

export default CheckoutFooter;
