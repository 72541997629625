import React, { useContext, useEffect, useRef, useState } from "react";
import "./PaymentSuccessful.css";
import OrderSubtitle from "./Components/OrderSubtitle/OrderSubtitle";
import UniversalCheckoutHeader from "../../UniversalComponents/CheckoutHeader/CheckoutHeader";
import { useLocation } from "react-router-dom";

const PaymentSuccessful = () => {
  return (
    <div className="checkout-summary-page">
      <div className="checkout">
        <UniversalCheckoutHeader
          titleText="Order Confirmed"
          subtitleText="Access it below"
          titleTextClass="universal-text-wrapper"
          subtitleTextClass="universal-div"
        />
        <OrderSubtitle />
        <footer className="footer">
          <div className="payment-successful-illus-container">
            <p className="text-wrapper-12">Streak Tech | All rights reserved</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
