import React, { useContext } from "react";
import "./Subtotal.css";
import { ItemDetailsData } from "../../../../Utils/DefinitionsInterfaces";
import { AuthContext, CartContext } from "../../../../App";
import { loader } from "../../../../Assets/svgs";

interface SubtotalProps {
  stateLoading: boolean;
  stateCheckoutItems: ItemDetailsData[];
  stateTotalSP: number;
  cbHandlePaymentClick: (orderIds: number[]) => void;
}

const Subtotal = (props: SubtotalProps) => {
  let currentItemIds: number[] = [];
  for (let i = 0; i < props.stateCheckoutItems.length; i++) {
    currentItemIds.push(props.stateCheckoutItems[i].id);
  }

  // const {
  //   auth: {
  //     student_username,
  //     name,
  //     phone,
  //     email,
  //     grade,
  //     report_generated,
  //     school,
  //     examDate,
  //     showMockOlympiad,
  //     examLink,
  //   },
  // } = useContext(AuthContext);

  return (
    <div className="subtotal">
      <div className="group-2">
        <div className="frame-wrapper">
          <div className="frame-2">
            <div className="div-wrapper">
              <div className="frame-3">
                <div className="text-wrapper-8">
                  Subtotal ({props.stateCheckoutItems.length} item):
                </div>
                <p className="element-2">
                  {/* <span className="text-wrapper-9">₹5</span> */}
                  {/* <span className="subtotal-text-wrapper-3">00</span> */}
                  <span className="subtotal-text-wrapper-3">
                    {"₹" + props.stateTotalSP}
                  </span>
                </p>
              </div>
            </div>
            <div className="text-wrapper-10">Inclusive of all taxes</div>
          </div>
        </div>
        <button
          className="subtotal-button"
          disabled={props.stateLoading || props.stateTotalSP === 0}
          onClick={() => {
            props.cbHandlePaymentClick(currentItemIds);
          }}
        >
          <div className="overlap-group-wrapper">
            <div
              className="overlap-group-3"
              style={{
                opacity: props.stateLoading ? "0.5" : "1",
              }}
            >
              {!props.stateLoading && (
                <div className="text-wrapper-11">Proceed to buy</div>
              )}
              {props.stateLoading && (
                <img className="text-wrapper-11" src={loader} alt="O" />
              )}
            </div>
          </div>
        </button>
      </div>
      <p className="you-will-gain-access">
        You will gain access to <br />
        this product immediately <br />
        after purchase.
      </p>
    </div>
  );
};

export default Subtotal;
