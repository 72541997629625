import React, { useContext } from "react";
import "./Cart.css";
import Item from "../Item/Item";
import Recommendations from "../Recommendations/Recommendations";
import {
  ItemDetailsData,
  ItemDetailsRecommended,
  ResponseCartItem,
} from "../../../../Utils/DefinitionsInterfaces";
import { CartContext } from "../../../../App";

interface CartProps {
  stateCheckoutItems: ItemDetailsData[];
  stateRecommendedItems: ItemDetailsData[];
  cbRemoveProduct: (itemId: number) => void;
  cbAddRecommended: (itemId: number) => void;
}

const Cart = (props: CartProps) => {
  const items = [];
  for (let i = 0; i < props.stateCheckoutItems.length; i++) {
    items.push(
      <Item
        stateCheckoutItem={props.stateCheckoutItems[i]}
        cbRemoveProduct={props.cbRemoveProduct}
        key={i + 1}
      />
    );
  }
  return (
    <div className="cart">
      <div className="cart-title">
        <div className="text-wrapper-2">Product description</div>
        <div className="text-wrapper-2">Price</div>
      </div>
      {items}
      {props.stateRecommendedItems.length > 0 && (
        <div className="text-wrapper-5">We recommend</div>
      )}
      <Recommendations
        stateRecommendedItems={props.stateRecommendedItems}
        cbAddRecommended={props.cbAddRecommended}
      />
    </div>
  );
};

export default Cart;
