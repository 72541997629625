import React from "react";
import "./CheckoutHeader.css";
import "../../UniversalStyles.css";
import { backArrowImage, backArrowWithoutFill } from "../../Assets/svgs";

interface CheckoutHeaderProps {
  imageUrl?: string;
  titleText: string;
  subtitleText: string;
  imageStyle?: React.CSSProperties;
  titleTextClass: string;
  subtitleTextClass: string;
}

const CheckoutHeader = (props: CheckoutHeaderProps) => {
  const handleBackClick = () => {
    sessionStorage.clear();
    window.location.href = "https://nfoportal.nationalfinanceolympiad.com/";
  };
  return (
    <header className="universal-checkout-header">
      <div className="universal-checkout-header-frame">
        <button
          className="back-to-portal-button"
          style={{
            display: "flex",
            flexDirection: "column",
            border: "none",
            background: "none",
            cursor: "pointer",
            fontFamily: "Cardo",
            fontSize: "18px",
            color: "#023b8f",
            top: "20px",
          }}
          onClick={handleBackClick}
        >
          <img src={backArrowImage} alt="<-" />
        </button>
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          {props.imageUrl && (
            <img
              className="universal-checkout-header-group"
              alt="Group"
              src={props.imageUrl}
              style={props.imageStyle}
            />
          )}
          <div className="universal-header-summary">
            <div className={props.titleTextClass}>{props.titleText}</div>
            <div className={props.subtitleTextClass}>{props.subtitleText}</div>
          </div>
        </div>
      </div>
      <img
        className="universal-NFO-logo"
        alt="Nfo logo"
        src="/img/nfo-logo.svg"
      />
    </header>
  );
};

export default CheckoutHeader;
