import React from "react";
import "./OrderSubtitle.css";
import Orders from "../Orders/Orders";
import { ItemDetailsData } from "../../../../Utils/DefinitionsInterfaces";

const OrderSubtitle = () => {
  return (
    <div className="order-subtitle">
      <Orders />
    </div>
  );
};

export default OrderSubtitle;
