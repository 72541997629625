import React, { useEffect, useState } from "react";
import "./Order.css";
import {
  ItemDetail,
  ItemDetailsData,
} from "../../../../Utils/DefinitionsInterfaces";
import { useNavigate } from "react-router-dom";

interface orderPropsInterface {
  stateCheckoutItem: ItemDetail[];
  invoiceNo: string;
}

const Order = (props: orderPropsInterface) => {
  const navigate = useNavigate();

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  return (
    <div className="order">
      {props.stateCheckoutItem.map((e) => {
        return (
          <div className="order-wrap" key={e.id}>
            <div className="order-content">
              <div className="order-content-wrap">
                <img className="order-pic" src={e.image} alt="Image" />
                <div className="order-title">
                  <div className="title">{e.name}</div>
                  <p className="description">{e.description}</p>
                </div>
              </div>
            </div>
            {e.id === 1 ? null : (
              <div className="order-frame">
                <div className="order-group">
                  <button
                    className="order-rectangle"
                    onClick={() => {
                      console.log(e);
                      if (e.id === 2) {
                        const token = localStorage.getItem(
                          "nfoCheckoutAccessToken"
                        );
                        if (token) {
                          openInSameTab(
                            "https://nfoportal.nationalfinanceolympiad.com/"
                          );
                        } else {
                          console.log("accesstoken not found in localstorage");
                        }
                      } else if (e.id === 28) {
                        openInSameTab(
                          "https://nfoportal.nationalfinanceolympiad.com/doubt-clearing-history"
                        );
                      } else {
                        navigate("/pdf-preview", {
                          state: {
                            type: e.name,
                            link: e.link,
                            goBack: true,
                          },
                        });
                      }
                    }}
                  >
                    <div className="order-text-wrapper-3">
                      {e.id === 28 ? "Access Sessions" : "Access product"}{" "}
                    </div>
                  </button>
                </div>

                <div
                  className="order-text-wrapper-4"
                  onClick={() => {
                    navigate("/invoice-preview", {
                      state: {
                        invoiceNo: props.invoiceNo,
                      },
                    });
                  }}
                >
                  Download Invoice
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Order;
