import React from "react";
import "./Item.css";
import {
  ItemDetailsData,
  ResponseCartItem,
} from "../../../../Utils/DefinitionsInterfaces";

interface ItemPropsInterface {
  stateCheckoutItem: ItemDetailsData;
  cbRemoveProduct: (itemId: number) => void;
}

const Item = (props: ItemPropsInterface) => {
  return (
    <div className="item">
      <div className="wrap">
        <div className="item-content">
          <img
            src={props.stateCheckoutItem.image}
            alt="photo"
            className="item-pic"
          />
          <div className="item-title">
            <div className="title">{props.stateCheckoutItem.name}</div>

            <p className="description">{props.stateCheckoutItem.description}</p>
            <button
              className="universal-item-remove-button"
              onClick={() => {
                props.cbRemoveProduct(props.stateCheckoutItem.id);
              }}
            >
              &nbsp; Remove Product
            </button>
          </div>
        </div>
        <div className="frame">
          <div className="element">
            <span className="text-wrapper-3">
              {"₹" + props.stateCheckoutItem.selling_price}
            </span>
            {props.stateCheckoutItem.selling_price <
              props.stateCheckoutItem.base_price && (
                <p className="item-p">
                  <del>
                    <span className="text-wrapper-3">
                      {"₹" + props.stateCheckoutItem.base_price}
                    </span>
                  </del>
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
