import React, { useEffect, useState } from "react";
import "./ItemInfoModal.css";
import { Modal } from "@mui/material";

import { arrowLeftWhite } from "../../../../Assets/svgs";

interface InfoModalInterface {
  children: React.ReactElement;
  image: string;
  title: string;
  description: string;
  isVisible: boolean;
  currentItemId: number;
  onClose: () => void;
  cbHandleCloseItemInfo: () => void;
  cbAddRecommended: (currentItemId: number) => void;
}

const ItemInfoModal = (props: InfoModalInterface) => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      props.cbHandleCloseItemInfo();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      props.cbHandleCloseItemInfo();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Modal
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        overflowY: "scroll",
      }}
      open={props.isVisible}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="item-info-wrap">
        <button
          className="item-info-back-button"
          onClick={props.cbHandleCloseItemInfo}
        >
          <img
            style={{
              top: "-5px",
            }}
            src={arrowLeftWhite}
            alt="&lt;-"
          />
          Go back
        </button>
        <br />
        <div className="item-info-container">
          <img
            style={{ height: "25%", width: "25%", objectFit: "contain" }}
            src={props.image}
          />
          <div className="item-info-title">{props.title}</div>
          <div className="item-info-desc">{props.description}</div>
          <div className="item-info-additembutton">
            <button
              className="item-info-overlap"
              onClick={() => {
                props.cbHandleCloseItemInfo();
                props.cbAddRecommended(props.currentItemId);
              }}
            >
              <div className="item-info-text-wrapper-7">+ Add item</div>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ItemInfoModal;
