import axios from "axios";
import api from "../axios/axiosMW";

export interface ServerResponseGeneral {
  status: number;
  message: "verify otp";
  errorCode: 0;
}

interface sendOtpResponse extends ServerResponseGeneral {
  data: string | Object;
}
interface verifyOtpResponse extends ServerResponseGeneral {
  data: string | Object;
}

interface ApiGetAdminDetailsResponse extends ServerResponseGeneral {
  data: {
    created_at: string;
    user_id: string;
    name: string;
    mobile_number: string;
    otp_verified: boolean;
  };
}

/**
 *
 * @param adminUserID
 * @returns
 */
export function sendOtp(adminUserID: string): Promise<sendOtpResponse> {
  console.log("Sending OTP");
  return api.post("admin-login-send-otp", {
    adminUserID,
  });
}

/**
 *
 * @param data
 * @returns
 */
export function verifyOtp(data: {
  adminUserID: string;
  otp: string;
}): Promise<verifyOtpResponse> {
  console.log("Verifying OTP");
  return api.post("admin-login-verify-otp", {
    ...data,
  });
}

export function getAdminDetails(): Promise<ApiGetAdminDetailsResponse> {
  return api.post("admin-details", {});
}

export interface getDetailsResponse extends ServerResponseGeneral {
  data: {
    created_at: string;
    school_id: number;
    student_username: string;
    name: string;
    phone: string;
    email: string;
    grade: number;
    batch_id: string;
    invite_sent: boolean;
    report_generated: boolean;
    exam_notification_sent: boolean;
    report_id: number;
    paid_for_mock_olympiad: boolean;
    notify_for_new_mock_olympiads: boolean;
    school: string;
    examDate: string;
    showMockOlympiad: boolean;
  };
}

export function getDetails(
  studentUserName: string,
  token: string
): Promise<getDetailsResponse> {
  return axios.post(
    "https://onboarding.streakcard.click/nfo/get-student-details",
    { studentUserName },
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
}

// Event Tracking request
export interface LogEventBody {
  studentUserID: string,
  eventName: string,
  loginEvent?: boolean,
  productIDs?: number[],
}

export interface LogEventResponse extends ServerResponseGeneral {
  data: any
}

export function logEvent(body: LogEventBody): Promise<LogEventResponse> {
  return api.post('log-event', { ...body })
}
