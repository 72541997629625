import React, { useContext, useEffect, useState } from "react";
import "./CartSubtitle.css";
import Cart from "../Cart/Cart";
import Subtotal from "../Subtotal/Subtotal";
import {
  ItemDetailsData,
  ItemDetailsRecommended,
  ResponseCartItem,
} from "../../../../Utils/DefinitionsInterfaces";
import { CartContext } from "../../../../App";

interface CartSubtitleProps {
  stateLoading: boolean;
  stateCheckoutItems: ItemDetailsData[];
  stateRecommendedItems: ItemDetailsData[];
  cbRemoveProduct: (itemId: number) => void;
  cbAddRecommended: (itemId: number) => void;
  cbHandlePaymentClick: (orderIds: number[]) => void;
}

const CartSubtitle = (props: CartSubtitleProps) => {
  let sumSellingPrice = 0;
  let sumBasePrice = 0;
  for (let i = 0; i < props.stateCheckoutItems.length; i++) {
    sumSellingPrice += props.stateCheckoutItems[i].selling_price;
    sumBasePrice += props.stateCheckoutItems[i].base_price;
  }

  const [totalSellingPrice, setTotalSellingPrice] =
    useState<number>(sumSellingPrice);
  const [totalBasePrice, setTotalBasePrice] = useState<number>(sumBasePrice);

  useEffect(() => {
    let sumSellingPrice = 0;
    let sumBasePrice = 0;
    for (let i = 0; i < props.stateCheckoutItems.length; i++) {
      sumSellingPrice += props.stateCheckoutItems[i].selling_price;
      sumBasePrice += props.stateCheckoutItems[i].base_price;
    }
    setTotalBasePrice(sumBasePrice);
    setTotalSellingPrice(sumSellingPrice);
  }, [props.stateCheckoutItems, props.stateRecommendedItems]);

  useEffect(() => {
    console.log("In Cart Subtitle : ");
    console.log(props.stateCheckoutItems);
    console.log(props.stateRecommendedItems);

    let sumSellingPrice = 0;
    let sumBasePrice = 0;
    for (let i = 0; i < props.stateCheckoutItems.length; i++) {
      sumSellingPrice += props.stateCheckoutItems[i].selling_price;
      sumBasePrice += props.stateCheckoutItems[i].base_price;
    }
    setTotalBasePrice(sumBasePrice);
    setTotalSellingPrice(sumSellingPrice);
  }, []);

  return (
    <div className="cart-subtitle">
      <Cart
        stateCheckoutItems={props.stateCheckoutItems}
        stateRecommendedItems={props.stateRecommendedItems}
        cbRemoveProduct={props.cbRemoveProduct}
        cbAddRecommended={props.cbAddRecommended}
      />
      <Subtotal
        stateLoading={props.stateLoading}
        stateCheckoutItems={props.stateCheckoutItems}
        stateTotalSP={totalSellingPrice}
        cbHandlePaymentClick={props.cbHandlePaymentClick}
      />
    </div>
  );
};

export default CartSubtitle;
